import { createContext, useState, useRef, useEffect } from "react";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  //   User Info
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isDoingAnotherScore, setIsDoingAnotherScore] = useState(false);

  //   User Selections and Inputs
  const [searched, setSearched] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [selectedProjectInfo, setSelectedProjectInfo] = useState({});
  const [albumInput, setAlbumInput] = useState("");

  const [selectedProjectId, setSelectedProjectId] = useState("");

  const searchFormRef = useRef();
  const audioRef = useRef(new Audio());
  // const audioRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTrackIds, setCurrentTrackIds] = useState([]);
  const [currentRatings, setCurrentRatings] = useState({});
  const [allSongsScored, setAllSongsScored] = useState();
  const [selectedScoreType, setSelectedScoreType] = useState("");
  const [favSongs, setFavSongs] = useState([]);

  // Btns
  const [switchToDisabled, setSwitchToDisabled] = useState(false);
  const [readyToBeActive, setReadyToBeActive] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  // Calculations and results for User
  const [connectionScore, setConnectionScore] = useState(0);
  const shareableImgRef = useRef();

  // Values
  const val = {
    userFirstName,
    setUserFirstName,
    userLastName,
    setUserLastName,
    userEmail,
    setUserEmail,
    albums,
    setAlbums,
    selectedScoreType,
    setSelectedScoreType,
    connectionScore,
    setConnectionScore,
    selectedProjectId,
    setSelectedProjectId,
    searched,
    setSearched,
    searchFormRef,
    isLoading,
    setIsLoading,
    selectedProjectInfo,
    setSelectedProjectInfo,
    albumInput,
    setAlbumInput,
    tracks,
    setTracks,
    favSongs,
    setFavSongs,
    shareableImgRef,
    allSongsScored,
    setAllSongsScored,
    currentTrackIds,
    setCurrentTrackIds,
    currentRatings,
    setCurrentRatings,
    switchToDisabled,
    setSwitchToDisabled,
    readyToBeActive,
    setReadyToBeActive,
    currentStep,
    setCurrentStep,
    audioRef,
    isDoingAnotherScore,
    setIsDoingAnotherScore,
  };

  return <UserContext.Provider value={val}>{children}</UserContext.Provider>;
};
