import { styled, Typography } from "@mui/material";
import React from "react";

const ShortenedText = styled(Typography)(({ theme }) => ({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
}));

export default ShortenedText;
