import {
  Box,
  Grid,
  Icon,
  ThemeProvider,
  Typography,
  createTheme,
  useTheme,
  useMediaQuery
} from "@mui/material";
import Image from "mui-image";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import ShortenedText from "../styled/ShortenedText";
import { useTruncatedString } from "../../utils/helpers";

function ImgDoubleCols({ connectionLevel, returnStampColor, displayOnly }) {
  const { selectedProjectInfo, connectionScore } = useContext(UserContext);
  // const [connectionLevel, setConnectionLevel] = useState("");
  const theme = useTheme();
  const widthBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

  const renderTruncatedString = useTruncatedString()

  let badgeStyle = !displayOnly ? {
    flex: 1,
    objectFit: "cover",
    width: "7rem",
    height: "3rem",
    maxWidth: "7rem",
    minWidth: "7rem",
  } 
  : 
  {
    flex: 1,
    objectFit: "cover",
    width: "100%",
  } 

const handleCapsVariant = () => {
  switch(true){
    case !displayOnly:
      return "h6";
      case widthBelowSm:
        return "subtitle2"; 
        // make a case for when its desktop size or make that the default
      default:
        return "body1"
  }
}

const handleCapsMarginBottom = () => {
  switch(true){
    case !displayOnly:
      return "1rem";
      case widthBelowSm:
        return "0.2rem"; 
        // make a case for when its desktop size or make that the default
      default:
        return "0.5rem"
  }
}

const handleScoreWidth = () => {
  switch(true){
    case !displayOnly:
      return "4.5rem";
      case widthBelowSm:
        return "2.35rem"; 
        // make a case for when its desktop size or make that the default
      default:
        return "3.5rem"
  }
}

const handleScorePadding = () => {
  switch(true){
    case !displayOnly:
      return "0.4rem 0";
      case widthBelowSm:
        return "0"; 
        // make a case for when its desktop size or make that the default
      default:
        return "0.2rem 0"
  }
}

const handleScorePaddingBottom = () => {
  switch(true){
    case !displayOnly:
      return "0.6rem";
      case widthBelowSm:
        return "0"; 
        // make a case for when its desktop size or make that the default
      default:
        return "0.2rem"
  }
}

const handleScoreVariant = () => {
  switch(true){
    case !displayOnly:
      return "h5";
      case widthBelowSm:
        return "subtitle2"; 
        // make a case for when its desktop size or make that the default
      default:
        return "h6"
  }
}

  // const handleConnectionLevel = () => {
  //   switch(true){
  //     case (connectionScore >= 9.0 && connectionScore <= 10):
  //       setConnectionLevel("classic");
  //       break;
  //       case (connectionScore >= 8.0 && connectionScore <= 8.9):
  //         setConnectionLevel("ultra");
  //         break;
  //         case (connectionScore >= 7.0 && connectionScore <= 7.9):
  //           setConnectionLevel("high");
  //           break;
  //           case (connectionScore >= 6.0 && connectionScore <= 6.9):
  //       setConnectionLevel("medium");
  //       break;
  //       case (connectionScore >= 5.0 && connectionScore <= 5.9):
  //       setConnectionLevel("low");
  //       break;
  //       case (connectionScore < 4.9):
  //       setConnectionLevel("notforme");
  //       break;
  //       default:
  //     return console.error("Invalid Value!")
  //   }
  // };

  // const returnStampColor = () => {
  //   switch (connectionLevel) {
  //     case "classic":
  //       // blackSecondary.main
  //       return "#000";

  //     case "ultra":
  //       // darkPurplePrimary.main
  //       return "#4d089a";

  //     case "high":
  //       // darkBluePrimary.main
  //       return "#090c9b";

  //     case "medium":
  //       // greenPrimary.main
  //       return "#218246";

  //     case "low":
  //       // orangePrimary.main
  //       return "#FF7B29";

  //     case "notforme":
  //       // redPrimary.main
  //       return "#FF1616";

  //     default:
  //       return null;
  //   }
  // };

  // useEffect(() => {
  //   handleConnectionLevel();
  // });

  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          margin: "2rem",
          alignItems: "center",
        }}
      > */}
      <Grid
        container
        item
        xs={4}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            // width: "50%",
            textAlign: "center",
          }}
        >
          <Image
            src={selectedProjectInfo.images[0].url}
            alt=""
            duration={0}
            sx={{
              width: "100%",
            }}
          />
        </Grid>

        <Grid
          container
          direction="column"
          item
          spacing={!displayOnly ? 4 : 1}
          xs={6}
          sx={{
            // width: "50%",
            textAlign: "center",
          }}
        >
          <Grid item>
            {!displayOnly ?
            <Typography
            variant="h5"
            component="p"
            sx={{
              fontWeight: 600,
              fontStyle: "italic",
            }}
            >
              {selectedProjectInfo.name}
            </Typography>
            :
            renderTruncatedString({
              customText: selectedProjectInfo.name,
              customVariant: widthBelowSm ? "body2" : "body1",
              customComponent: "p",
              customSx: {
                fontWeight: 600,
                fontStyle: "italic",
              },
              maxCharacters: {
                sm: widthBelowSm ? 8 : 15,
                smToMd: 15,
                aboveMd: 15,
              },
            })}
            {/* {selectedProjectInfo.name} */}
            {!displayOnly ? 
            <Typography
            variant="h5"
            component="p"
            sx={{
              fontWeight: 600,
            }}
            >
              {selectedProjectInfo.artists[0].name}
            </Typography>
            :
            renderTruncatedString({
              customText: selectedProjectInfo.artists[0].name,
              customVariant: widthBelowSm ? "caption" : "body2",
              customComponent: "p",
              customSx: {
                fontWeight: 600,
                fontStyle: "italic",
              },
              maxCharacters: {
                sm: widthBelowSm ? 8 : 14,
                smToMd: 14,
                aboveMd: 14,
              },
            })}
          </Grid>

          <Grid item>
            <Typography
              component="p"
              variant={handleCapsVariant()}
              sx={{
                fontWeight: "400",
                textTransform: "uppercase",
                fontSize: (widthBelowSm && displayOnly) && "0.65rem",
                marginBottom: handleCapsMarginBottom(),
              }}
            >
              Connection Level
            </Typography>
            <Box
              sx={ displayOnly ? {
                display: "flex",
                width: widthBelowSm ? "3rem" : "4rem",
                margin: "auto",
              } : {}}
            >
              <Image
                src={`/images/badges/${connectionLevel}badge_v4.PNG`}
                alt=""
                duration={0}
                sx={badgeStyle}
              />
            </Box>
          </Grid>

          <Grid item>
            <Typography
              component="p"
              variant={handleCapsVariant()}
              sx={{
                fontWeight: "400",
                fontSize: (widthBelowSm && displayOnly) && "0.65rem",
                textTransform: "uppercase",
                marginBottom: handleCapsMarginBottom(),
              }}
            >
              Connection Score
            </Typography>
            <Box
              sx={{
                // fontSize: "1.5rem",
                width: handleScoreWidth(),
                padding: handleScorePadding(),
                paddingBottom: handleScorePaddingBottom(),
                margin: "auto",
                borderRadius: "0.3rem",
                fontWeight: "bold",
                backgroundColor: returnStampColor(),
              }}
            >
              <Typography variant={handleScoreVariant()} component="span">
                {connectionScore}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ImgDoubleCols;
