import { ListItemButton, styled } from '@mui/material'
import React from 'react'

const ProjectListButton = styled(ListItemButton)(({ theme }) => ({
    border: `3px solid  ${theme.palette.blackSecondary.main}`,
    borderRadius: "1.5rem",
    padding: 0,
    overflow: "hidden",
}))

export default ProjectListButton