import { useTheme } from "@emotion/react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Link
} from "@mui/material";
import React from "react";
import ActionButton from "../styled/ActionButton";

function WhatsNextDialog({ handleClose, open }) {
  const theme = useTheme();

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: theme.palette.blackSecondary.main,
          textAlign: "center"
        }}
      >
        <DialogTitle>
          Thanks for downloading your COMP Score!
        </DialogTitle>
        <DialogContent>
        <ActionButton
                        href="https://compinitiative.com/after-the-score"
                        sx={{
                          bgcolor: theme.palette.darkPurplePrimary.main,
                        }}
                      >
                        Now What?
                      </ActionButton>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default WhatsNextDialog;
