import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/material";
import Image from "mui-image";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import ScoreTypesList from "../../components/listChoices/ScoreTypesList/ScoreTypesList";
import PageArrows from "../../components/PageArrows/PageArrows";
import ProjectListButton from "../../components/styled/ProjectListButton";
import ShortenedText from "../../components/styled/ShortenedText";
import { STEPS } from "../../utils/constants";
import { StepContext } from "../../utils/StepContext";
import { UserContext } from "../../utils/UserContext";
import { useTruncatedString } from "../../utils/helpers";

function ScoreType() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const renderTruncatedString = useTruncatedString()

  const scoreBtnDetails = [
    {
      id: 1,
      name: "Nostalgia",
      summary: "as your past self",
      color: "pink",
    },
    {
      id: 2,
      name: "React",
      summary: "your first listen",
      color: "green",
    },
    {
      id: 3,
      name: "Replay",
      summary: "your recent relisten",
      color: "orange",
    },
  ];

  const {
    tracks,
    selectedScoreType,
    setSelectedScoreType,
    selectedProjectInfo,
    setAlbums,
    setAlbumInput,
    setSwitchToDisabled,
    setReadyToBeActive,
    searched,
    isLoading,
  } = useContext(UserContext);

  const theme = useTheme();

  const { setStep } = useContext(StepContext);

  const [scoreBtnSelected, setScoreBtnSelected] = useState();

  useEffect(() => {
    if (!selectedScoreType) {
      setSwitchToDisabled(true);
      setReadyToBeActive(false);
    } else {
      setSwitchToDisabled(false);
      setReadyToBeActive(true);
    }
  }, [selectedScoreType]);

  const prevStep = () => {
    if (window.sessionStorage !== undefined) {
      const input = window.sessionStorage.getItem("AlbumInput");
      input !== null ? setAlbumInput(JSON.parse(input)) : setAlbumInput(null);

      const data = window.sessionStorage.getItem("AlbumData");
      data !== null ? setAlbums(JSON.parse(data)) : setAlbums(null);
    }
    setStep(STEPS.AFTER_SEARCH);
  };

  const nextStep = () => {
    if (selectedScoreType) {
      setScoreBtnSelected(true);
      setStep(STEPS.SORT);
    } else {
      setScoreBtnSelected(false);
    }
  };


  return (
    <>
      <Header prevStep={prevStep} nextStep={nextStep} />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "calc(2rem + 100px)",
        }}
      >
        <Grid item>
          <Box
            component="section"
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0 20px",
              textAlign: "center",
            }}
          >
            <Grid container alignItems="center" direction="column" spacing={2}>
              <Grid item>
                <ProjectListButton
                  sx={{
                    bgcolor: theme.palette.darkBluePrimary.main,
                    cursor: "auto",
                    minWidth: "17rem",
                  }}
                >
                  <img
                    src={selectedProjectInfo.images[0].url}
                    alt=""
                    style={{
                      width: "6.5rem",
                      height: "6.5rem",
                    }}
                  />
                  <ListItemText
                    sx={{
                      margin: "0.5rem",
                    }}
                    primary={
                      // <ShortenedText
                      //   sx={{
                      //     fontWeight: 600,
                      //     display: "block",
                      //     WebkitLineClamp: 2,
                      //   }}
                      //   component="span"
                      //   variant="h6"
                      //   color={theme.palette.whitePrimary.main}
                      // >
                      //   {selectedProjectInfo.name}
                      // </ShortenedText>
                      renderTruncatedString({
                        customText: selectedProjectInfo.name,
                        customVariant: "h6",
                        customComponent: "span",
                        customSx: {
                          fontWeight: 600,
                          display: "block",
                          color: theme.palette.whitePrimary.main,
                        },
                        maxCharacters: {
                          sm: 13,
                          smToMd: 37,
                          aboveMd: 63,
                        },
                      })
                    }
                    secondary={
                      <ShortenedText
                        sx={{
                          display: "inline",
                          WebkitLineClamp: 1,
                        }}
                        component="span"
                        variant="body1"
                        color={theme.palette.whitePrimary.main}
                      >
                        {selectedProjectInfo.artists[0].name}
                      </ShortenedText>
                    }
                  ></ListItemText>
                </ProjectListButton>
              </Grid>

              <Grid item>
                <Typography
                  component="h1"
                  variant="h6"
                  sx={{
                    // fontSize:"1.8rem",
                    fontWeight: 800,
                    padding: "0 1rem",
                  }}
                >
                  {/* What type of score are you doing for this project? */}
                  Pick a score type for this project.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          textAlign: "center",
        }}
      > */}

      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          textAlign: "center",
          paddingBottom: "7rem",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={8}>
            <ScoreTypesList />
          </Grid>
        </Grid>
      </Box>

      <PageArrows prevStep={prevStep} nextStep={nextStep} />
    </>
  );
}

export default ScoreType;
