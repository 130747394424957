import {
  AppBar,
  Box,
  Icon,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useTheme,
  styled,
  StepButton,
  Paper,
  Toolbar,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { STEPS } from "../../utils/constants";
import { StepContext } from "../../utils/StepContext";
import { UserContext } from "../../utils/UserContext";
// import TopPageStepIcon from "../styled/TopPageStepIcon";

function Header({ prevStep, nextStep }) {
  const { step } = useContext(StepContext);
  const { readyToBeActive, setReadyToBeActive, currentStep, setCurrentStep } =
    useContext(UserContext);

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return Object.keys(STEPS).length;
  };

  const totalStepperSteps = () => {
    return Object.keys(STEPS).length - 2;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    // Resets to "Search Page"
    setActiveStep(1);
  };

  // for material ui appbar
  const pageButtonsDetails = [
    {
      id: 1,
      title: "Search",
      active: step === STEPS.AFTER_SEARCH && true,
      // imageUrl: `/images/btnIcons/search-${
      //   step === STEPS.SEARCH ? "white" : "dark"
      // }-outline.png`,
    },
    {
      id: 2,
      title: "Score Type",
      active: step === STEPS.SCORETYPE && true,
      // imageUrl: `/images/btnIcons/score-type-${
      //   step === STEPS.SCORETYPE ? "white" : "dark"
      // }-outline.png`,
    },
    {
      id: 3,
      title: "Sort",
      active: step === STEPS.SORT && true,
      // imageUrl: `/images/btnIcons/sort-${
      //   step === STEPS.SORT ? "white" : "dark"
      // }-outline.png`,
    },
    {
      id: 4,
      title: "Favorites",
      active: step === STEPS.FAVORITE && true,
      // imageUrl: `/images/btnIcons/star-${
      //   step === STEPS.FAVORITE ? "white" : "dark"
      // }-outline.png`,
    },
    {
      id: 5,
      title: "Score",
      active: step === STEPS.FINALSCORE && true,
      // imageUrl: `/images/btnIcons/score-${
      //   step === STEPS.FINALSCORE ? "white" : "dark"
      // }-outline.png`,
    },
  ];

  return (
    // <Box
    //   sx={{
    //     position: "relative",
    //     width: "100%",
    //   }}
    //   component="header"
    // >
    <Box
      component="header"
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 2,
        height: step !== STEPS.HOME && 120,
        bgcolor: theme.palette.blackPrimary.main,
        paddingTop: step !== STEPS.HOME && "1.5rem",
      }}
    >
      {step !== STEPS.HOME && (
        <Stepper alternativeLabel activeStep={currentStep}>
          {pageButtonsDetails.map((pageButton) => (
            <Step key={pageButton.title}>
              <StepLabel>{pageButton.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </Box>

    // </Box>
  );
}

export default Header;
