import { createContext, useState } from "react";
import { STEPS } from "./constants";


export const StepContext = createContext()

export const StepContextProvider = ({ children }) => {
    const [step, setStep] = useState(STEPS.HOME)

    const val = {
        step,
        setStep
    }

    return <StepContext.Provider value={val}>{children}</StepContext.Provider>
}

