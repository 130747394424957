import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import FavoriteSongsList from "../../components/listChoices/FavoriteSongsList/FavoriteSongsList";
import PageArrows from "../../components/PageArrows/PageArrows";
import { STEPS } from "../../utils/constants";
import { StepContext } from "../../utils/StepContext";
import { UserContext } from "../../utils/UserContext";
import UserForm from "../../components/UserForm/UserForm";

function FavoriteSongs() {

  useEffect(() => {
    window.scrollTo(0, 0)
  });

  const { tracks, favSongs, setFavSongs, selectedScoreType, isDoingAnotherScore } =
    useContext(UserContext);

  const { setStep } = useContext(StepContext);

  const connectionType = () => {
    switch (selectedScoreType) {
      case "Nostalgia":
        return "past";
      case "React":
        return "first";
      case "Rotation":
        return "recent";
      default:
        return null;
    }
  };


  const [open, setOpen] = useState(false);
  const handleOpenForm = () => setOpen(true);
  const handleCloseForm = () => setOpen(false);

  const prevStep = () => {
    setStep(STEPS.SORT);
  };

  const nextStep = () => {
    !isDoingAnotherScore ?
    handleOpenForm() :
    setStep(STEPS.FINALSCORE);
  };



  return (
    <>
      <Header prevStep={prevStep} nextStep={nextStep} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "calc(2rem + 100px)",
          textAlign: "center",
        }}
      >
        <Grid item>
          <Typography component="h1" variant="directions"
          sx={{
            padding: "0 1rem",
          }}
          >
            Select up to 3 of your favorite songs from your {connectionType()}{" "}
            listens to this project.
          </Typography>
        </Grid>
      </Grid>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          textAlign: "center",
          paddingBottom: "7rem",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={8}>
            <FavoriteSongsList />
          </Grid>
        </Grid>
      </Box>
      <PageArrows prevStep={prevStep} nextStep={nextStep} />
      
     { !isDoingAnotherScore && (
     <UserForm
      open={open}
      handleClose={handleCloseForm}
      />
      )
      }
    </>
  );
}

export default FavoriteSongs;
