import React, { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../utils/UserContext";
import {
  Grid,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { Star } from "@mui/icons-material";
import Image from "mui-image";
import ShortenedText from "../styled/ShortenedText";
import { useTruncatedString } from "../../utils/helpers";

function TimeOfImage({displayOnly}) {
  const { selectedScoreType, favSongs } = useContext(UserContext);
  const shareableImageTheme = useTheme();
    const widthBelowSm = useMediaQuery(shareableImageTheme.breakpoints.down("sm"));

  const renderTruncatedString = useTruncatedString()
  const now = new Date();

  const currentDate = () => {
    let month = now.getMonth() + 1;
    let day = now.getDate();
    let year = now.getFullYear();

    if (month.toString().length === 1) {
      month = "0" + month;
    }
    if (day.toString().length === 1) {
      day = "0" + day;
    }

    const finalDate = `${month}.${day}.${year}`;

    return finalDate;
  };

  const currentTime = () => {
    let hours = now.getHours();
    let minutes = now.getMinutes();

    //     if(hours.toString().length === 1) {
    //       hours = '0'+hours;
    //  }
    //  if(minutes.toString().length === 1) {
    //       minutes = '0'+minutes;
    //  }

    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const finalTime = `${hours}:${minutes} ${ampm}`;

    return finalTime;
  };

  // const favSongsFunc = () => {
  //   if (favSongs.length >= 1 ) {
  //     for( let fav of favSongs) {
  //        <p>{fav}</p>
  //     }
  //   }
  // }

  const handleFavMaxChars = () => {
    switch(true){
      case !displayOnly:
        return 21;
        case widthBelowSm: 
        return 6;
        default: 
        return 10;
    }
  }

  const handleLogoMaxWidth = () => {
    switch(true){
      case !displayOnly:
        return "4rem";
        case (widthBelowSm && displayOnly): 
        return "1.75rem";
        default: 
        return "2.5rem";
    }
  }

  const favSongsElements = favSongs.map((fav) => (
    <ListItem
      key={fav}
      sx={{
        marginTop: "-4px",
        marginBottom: !displayOnly ?  "8px" : 0
      }}
      disableGutters
    >
      <ListItemIcon>
        {/* <Star
          sx={{
            color: displayImageTheme.palette.goldPrimary,
            width: "0.6em",
          }}
        /> */}
        <img
          src="/images/extra/gold-star.png"
          alt=""
          width={(widthBelowSm && displayOnly) ? "10px" : "15px"}
        />
        {/* <img
        src="/public/images/extra/gold-star.png"
        alt=""
        style={{width: "0.6em"}}
  
        /> */}
      </ListItemIcon>
      <ListItemText
        primary={
          renderTruncatedString({
          customText: fav,
          customVariant: !displayOnly ? "body2" : "caption",
          customComponent: "span",
          customSx: {
            WebkitLineClamp: 1,
            paddingBottom: 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          },
          maxCharacters: {
            sm: handleFavMaxChars(),
            smToMd: handleFavMaxChars(),
            aboveMd: handleFavMaxChars(),
          },
        })}
      />
    </ListItem>
  ));

  return (
    <Grid
      container
      item
      xs={4}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      sx={{
        flexWrap: "nowrap",
      }}
    >
      <Grid item 
      // xs={favSongs.length === 0 ? 4 : "auto"}
      xs={4}
      >
        { favSongs.length === 0 ? <Paper></Paper>
         :
        <List dense>{favSongsElements}</List>}
      </Grid>

      <Grid
        item
        xs={4}
        // sx={{
        //   textAlign: "center",
        // }}
      >
        <Image
          src="/images/logo/comp-logo.png"
          alt="COMP Logo"
          duration={0}
          sx={{
            maxWidth: handleLogoMaxWidth(),
          }}
        />
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          textAlign: "right",
        }}
      >
        <List dense>
        <ListItem
      sx={{
        marginTop: "-4px",
        marginBottom: !displayOnly ?  "8px" : 0,
        textAlign: "right",
      }}
      disableGutters
    >
      <ListItemText
        primary={<Typography
          variant={!displayOnly ? "body2" : "caption"}
          component="span"
          sx={{
            WebkitLineClamp: 1,
            paddingBottom: 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          }}
        >
          {currentDate()}
        </Typography>}
      />
    </ListItem>

    <ListItem
      sx={{
        marginTop: "-4px",
        marginBottom: !displayOnly ?  "8px" : 0,
        textAlign: "right",
      }}
      disableGutters
    >
      <ListItemText
        primary={<Typography
          variant={!displayOnly ? "body2" : "caption"}
          component="span"
          sx={{
            WebkitLineClamp: 1,
            paddingBottom: 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          }}
        >
          {currentTime()}
        </Typography>}
      />
    </ListItem>

    <ListItem
      sx={{
        marginTop: "-4px",
        marginBottom: !displayOnly ?  "8px" : 0,
        textAlign: "right",
      }}
      disableGutters
    >
      <ListItemText
        primary={<Typography
          variant={!displayOnly ? "body2" : "caption"}
          component="span"
          sx={{
            WebkitLineClamp: 1,
            paddingBottom: 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          }}
        >
          {selectedScoreType}
        </Typography>}
      />
    </ListItem>
        </List>
        {/* <Typography selectedScoreType
          variant={!displayOnly ? "body2" : "caption"}
          sx={{
            display: "block",
            paddingBottom: !displayOnly ?  "6px" : 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          }}
        >
          {currentDate()}
        </Typography>
        <Typography
          variant={!displayOnly ? "body2" : "caption"}
          sx={{
            display: "block",
            paddingBottom: !displayOnly ?  "6px" : 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          }}
        >
          {currentTime()}
        </Typography>
        <Typography
          variant={!displayOnly ? "body2" : "caption"}
          sx={{
            textTransform: "uppercase",
            display: "block",
            paddingBottom: !displayOnly ?  "6px" : 0,
            fontSize: (widthBelowSm && displayOnly) && "0.5rem",
          }}
        >
          {selectedScoreType}
        </Typography> */}
      </Grid>
    </Grid>
  );
}

export default TimeOfImage;
