import { useState, useEffect, useRef, useContext } from "react";
import "./SearchForm.css";
import { Controller, useForm } from "react-hook-form";
import { UserContext } from "../../utils/UserContext";
import { STEPS } from "../../utils/constants";
import PageArrows from "../PageArrows/PageArrows";
import { StepContext } from "../../utils/StepContext";
import {
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import ResponsiveTextField from "../styled/ResponsiveTextField";

function SearchForm() {
  const {
    setValue,
    handleSubmit,
    control,
    resetField,
    formState: { errors, isDirty, isSubmitted },
  } = useForm({
    defaultValues: {
      albumSearch: "",
    },
  });

  const spotifyCredentials = {
    ClientId: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
    ClientSecret: process.env.REACT_APP_SPOTIFY_CLIENT_SECRET,
  };

  const {
    albums,
    setAlbums,
    searched,
    setSearched,
    searchFormRef,
    setIsLoading,
    albumInput,
    setAlbumInput,
    selectedProjectId,
    tracks,
    setTracks,
    setUserFirstName,
    setUserLastName,
    setCurrentStep,
    setUserEmail,
    selectedProjectInfo,
  } = useContext(UserContext);

  useEffect(() => {
    console.log(selectedProjectInfo)
  }, [selectedProjectInfo])

  const [accessToken, setAccessToken] = useState("");

  const { step, setStep } = useContext(StepContext);

  const albumInputRef = useRef(albumInput);

  const theme = useTheme()

  const widthBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

  function updateAlbumInputState(newState) {
    albumInputRef.current = newState;
    setAlbumInput(newState);
  }

  const onSubmit = (data) => {
    setAlbumInput(data.albumSearch);
    setSearched(!searched);
  };


  useEffect(() => {
    if (albumInput.length >= 1) {
      window.sessionStorage.setItem("AlbumInput", JSON.stringify(albumInput));
      updateAlbumInputState(albumInput);
      setValue("albumSearch", albumInput);
      search();
    }


    // API Access Token

    let authParameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body:
        "grant_type=client_credentials&client_id=" +
        spotifyCredentials.ClientId +
        "&client_secret=" +
        spotifyCredentials.ClientSecret,
    };
    if (Object.keys(albums).length === 0) {
      setIsLoading(true);
    }
    fetch("https://accounts.spotify.com/api/token", authParameters)
      .then((result) => result.json())
      .then((data) => setAccessToken(data.access_token));
  }, [albumInput]);



  // Search
  async function search() {
    // Get request for Artist ID
    let searchParamters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    let albumResults = await fetch(
      "https://api.spotify.com/v1/search?q=" +
        albumInput +
        "&type=album&limit=50",
      searchParamters
    )
      .then((response) => response.json())
      .then((data) => {
        // return data.albums.items;
        let filteredItems = data.albums.items.filter(
          (version, i, alb) =>
            alb.findIndex((version2) => version2.name === version.name) === i
        );


        let actualAlbums = filteredItems.filter((item) => {
          return item.album_type === "album";
        });
        window.sessionStorage.setItem(
          "AlbumData",
          JSON.stringify(actualAlbums)
        );
        setAlbums(actualAlbums);
        setIsLoading(false);
        // setStep(STEPS.AFTER_SEARCH)
      });
  }

  // Get tracks from album
  async function getTracks() {
    // Get request for tracks
    let searchParamters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    // Get request for all tracks from album id from selected project
    let returnedTracks = await fetch(
      "https://api.spotify.com/v1/albums/" +
        selectedProjectId +
        "/tracks?include_groups=album&market=US&limit=50",
      searchParamters
    )
      .then((response) => response.json())
      .then((data) => {
        // filter items to avoid showing duplicates
        setTracks(data.items);
      });
  }


useEffect(() => {
  if(selectedProjectId) {
    getTracks()
  }
}, [selectedProjectId])

 
  
  return (
 <>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "0 20px",
          textAlign: "center",
        }}
        onSubmit={handleSubmit(onSubmit)}
        ref={searchFormRef}
      >
        <Grid container alignItems="center" direction="column" spacing={2}>
        <Grid item>
            <Typography component="h1" 
            variant="directions"
            sx={{
              padding: "0rem",
              fontFamily: "Space Mono, monospace",
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
            >
               What project do you want to score?
            </Typography>
          </Grid>
          <Grid item container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item>
            <Controller
              control={control}
              name="albumSearch"
              rules={{
                required: "Enter an artist or album",
              }}
              // get the field state
              render={({ field, fieldState: { error } }) => (
                <ResponsiveTextField
                  {...field}
                  variant="outlined"
                  type="text"
                  placeholder={widthBelowSm ? "Artist or album" : "Search for an artist or album"}
              // inputProps={
              //   { readOnly: searched ? true : false }
              // }
                  //  is error ??
                  error={errors.albumSearch ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* { isDirty && (<SvgIcon>
                          <ClearIcon sx={{ color: "#000" }} />
                        </SvgIcon>)} */}
                        {/* <SvgIcon>
                          <ClearIcon sx={{ color: isDirty ? "#000" : "transparent" }} />
                        </SvgIcon> */}
                        <IconButton
                        aria-label="clear input"
                        disabled={!isDirty || (isSubmitted && albumInput.length < 1)}
                        onClick={() => {
                          resetField("albumSearch")
                        }}
                        // sx={{
                        //   cursor: isDirty ? "pointer" : "auto",
                        // }}
                        edge="end"
                        >
                           <ClearIcon sx={{ color: !isDirty || (isSubmitted && albumInput.length < 1) ? "transparent" : "#000" }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.albumSearch?.message}
            </Typography>
            </Grid>

            <Grid item>
          {!widthBelowSm ?
            <Button
            disabled={!isDirty || (isSubmitted && albumInput.length < 1)}
            sx={{
              bgcolor: theme.palette.darkBluePrimary.main,
              fontFamily: "Space Mono, monospace",
              padding: "0.3rem 1.5rem",
              borderRadius: "1.2rem",
              "&.Mui-disabled": {
              bgcolor: theme.palette.darkGrayPrimary.main,
              }
              
          }}
           type="submit">
              Search
            </Button>
            : 
            <IconButton
            type="submit"
                        aria-label="search"
                        disabled={!isDirty || (isSubmitted && albumInput.length < 1)}
                        sx={{
                          bgcolor: theme.palette.darkBluePrimary.main,
              padding: "0.5rem",
              borderRadius: "50%",
              "&.Mui-disabled": {
                bgcolor: theme.palette.darkGrayPrimary.main,
              }
                        }}
                        >
                           <SearchIcon sx={{
                            color: isDirty && theme.palette.whitePrimary.main,
                           }} />
                        </IconButton>
          }
          </Grid>
          </Grid>
          {/* </form> */}
        </Grid>
      </Box>
</>
  );
}

export default SearchForm;
