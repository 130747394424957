import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../utils/UserContext";
import { STEPS } from "../../utils/constants";
import { StepContext } from "../../utils/StepContext";
import PageArrows from "../../components/PageArrows/PageArrows";
import Header from "../../components/Header/Header";
import {
  Box,
  Chip,
  Grid,
  ListItem,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SongScoringList from "../../components/listChoices/SongScoringList/SongScoringList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceGrimace,
  faFaceGrin,
  faFaceGrinHearts,
  faFaceMeh,
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons";

function SongScoring() {
  const {
    tracks,
    setConnectionScore,
    selectedScoreType,
    allSongsScored,
    setAllSongsScored,
    currentTrackIds,
    setCurrentTrackIds,
    currentRatings,
    setCurrentRatings,
    setSwitchToDisabled,
    audioRef,
  } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { setStep } = useContext(StepContext);

  const [trackToPlay, setTrackToPlay] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  const { preview_url } = tracks[trackToPlay];

  const [theTrackId, setTheTrackId] = useState();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const ratingButtonsInfo = [
    {
      number: "one",
      color: "darkGrayPrimary.main",
      icon: faFaceGrimace,
      value: "Skip it",
      score: 1,
    },
    {
      number: "two",
      color: "darkPinkPrimary.main",
      icon: faFaceMeh,
      value: "Okay with it",
      score: 2,
    },
    {
      number: "three",
      color: "greenPrimary.main",
      icon: faFaceSmile,
      value: "Like it",
      score: 3,
    },
    {
      number: "four",
      color: "darkBluePrimary.main",
      icon: faFaceGrin,
      value: "Really like it",
      score: 4,
    },
    {
      number: "five",
      color: "darkPurplePrimary.main",
      icon: faFaceGrinHearts,
      value: "Love it",
      score: 5,
    },
  ];

  const ratingButtonsGuide = ratingButtonsInfo.map((ratingBtn) => (
    <Chip
      key={ratingBtn.number}
      icon={
        <FontAwesomeIcon
          icon={ratingBtn.icon}
          size="2xl"
          style={{ color: "#fff" }}
        />
      }
      label={ratingBtn.value}
      sx={{
        color: theme.palette.whitePrimary.main,
        border: `2px solid ${theme.palette.whitePrimary.main}`,
        height: 44,
        fontSize: "1rem",
      }}
      variant="outlined"
      size="medium"
    />
  ));

  const connectionType = () => {
    switch (selectedScoreType) {
      case "Nostalgia":
        return "made";
      case "React":
        return "first made";
      case "Replay":
        return "makes";
      default:
        return null;
    }
  };

  const compScoreFinalCalculation = () => {
    // Firstly, the number of tracks on the music project is multiplied by 5 (this is the overall possible total).
    let overallPossibleTotal = currentTrackIds.length * 5;

    let arrOfRatings = [];

    for (let id of currentTrackIds) {
      arrOfRatings.push(currentRatings[id].score);
    }

    // Next, for every possible selected track, the number values for every scored track is added together (this is the scorer’s cumulative total).
    let cumulativeTotal = 0;
    for (let song of arrOfRatings) {
      cumulativeTotal += song;
    }

    // We divide the scorer’s cumulative total/overall possible total and then multiply by 10.
    let score = (cumulativeTotal / overallPossibleTotal) * 10;

    // It should always produce a #.# value. We will always round up to the nearest tenth.

    let finalScore = parseFloat(score.toFixed(1));
    setConnectionScore(finalScore);
  };

  const prevStep = () => {
    audioRef.current.pause();
    setStep(STEPS.SCORETYPE);
  };

  const nextStep = () => {
    if (
      tracks.length === currentTrackIds.length &&
      Object.keys(currentRatings).length === currentTrackIds.length
    ) {
      audioRef.current.pause();
      setAllSongsScored(true);
      compScoreFinalCalculation();
      setStep(STEPS.FAVORITE);
    } else {
      setAllSongsScored(false);
    }
  };

  return (
    <>
      <Header prevStep={prevStep} nextStep={nextStep} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "calc(2rem + 100px)",
          textAlign: "center",
        }}
      >
        <Grid item>
          <Typography
            component="h1"
            variant={mobile ? "mobileDirections" : "directions"}
            sx={{
              padding: "0 1rem",
            }}
          >
            Select how each song {connectionType()} you feel.
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            justifyContent="center"
            sx={{
              gap: "0.35rem",
            }}
          >
            {ratingButtonsGuide}
          </Stack>
        </Grid>
      </Grid>
      {/* {allSongsScored === false && (
          <p className="error-message">Please score all songs</p>
        )} */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          textAlign: "center",
          paddingBottom: "7rem",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sm={8}>
            <SongScoringList ratingButtonsInfo={ratingButtonsInfo} />
          </Grid>
        </Grid>
      </Box>
      <PageArrows prevStep={prevStep} nextStep={nextStep} />
    </>
  );
}

export default SongScoring;
