import { useTheme } from "@emotion/react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import React from "react";

function FairUseDialog({ handleClose, open }) {
  const theme = useTheme();

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box
        sx={{
          bgcolor: theme.palette.blackSecondary.main,
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontFamily: "Space Mono, monospace",
          }}
        >
          Fair Use Statement
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1"
          sx={{fontFamily: "Space Mono, monospace"
          }}>
            The material generated by this site is for informational use only.
            All Intellectual Property not directly created by COMP has been
            credited back to its original source or is believed to be in the
            public domain. Since this is a form of social commentary, it is
            believed that this constitutes a “fair use” of any such copyrighted
            material under Section 107 of the US Copyright Law. In accordance
            with Title 17 U.S.C. Section 107, the material on this site is
            distributed without profit to those who have an interest in using
            the included information for research or informational purposes. If
            you wish to use copyrighted material from this site for purposes of
            your own that go beyond “fair use,” you must obtain permission from
            COMP or the music copyright owner. The information on this site does
            not constitute legal or technical advice.
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default FairUseDialog;
