import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import Header from "../../components/Header/Header";
import InfiniteSlider from "../../components/ui/InfiniteSlider/InfiniteSlider";
import UserForm from "../../components/UserForm/UserForm";
import CopyrightStatement from "../../components/CopyrightStatement/CopyrightStatement";
import { StepContext } from "../../utils/StepContext";
import { STEPS } from "../../utils/constants";
import { useTheme } from "@emotion/react";


function Homepage() {
  const { setStep } = useContext(StepContext);

  const theme = useTheme()

  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{
          marginTop: "3rem",
          height: "100%",
          textAlign: "center",
          paddingBottom: "5rem",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* <div className="app-name-container"> */}
          <Grid item>
            <Typography
              component="h1"
              variant="h2"
              sx={{
                color: "#ffffff",
                textShadow: "4px 2px #4d089a, 6px 4px #090c9b,  8px 6px #a20054, 10px 8px #218246",
                padding: "0.5rem 0",
                fontWeight: 800,
                fontStyle: "italic",// Changed into Italics
                fontFamily: "Space Mono, monospace", // Changed into COMP Font
                WebkitTextStroke: "1.5px black", // Added text stroke to make the text more visible
              }}
            >
              COMP Score Generator
            </Typography>
            {/* </div> */}
          </Grid>
          <Grid item>
            <InfiniteSlider />
          </Grid>

          <Grid item>
            <Box
              sx={{
                margin: "auto",
                position: "relative",
                width: "21rem",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  padding: "1rem 0",
                  margin: "0 30px",
                  fontStyle: "bold",
                  fontFamily: "Space Mono, monospace",
                }}
              >
                Music is about connection. No judgement. No critique. Your
                music taste. Your COMP Score.
              </Typography>
            </Box>
          </Grid>

          <Grid item>
            <Button
              onClick={() => {
                setStep(STEPS.SEARCH);
              }}
              sx={{
                bgcolor: theme.palette.darkBluePrimary.main,
                fontFamily: "Space Mono, monospace",
              }}
            >
              Let's Score
            </Button>
          </Grid>
        </Grid>
      </Box>
      <CopyrightStatement />
    </>
  );
}

export default Homepage;
