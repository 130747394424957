import React, { useContext, useEffect, useReducer, useState } from "react";
import { STEPS } from "../../utils/constants";
import { StepContext } from "../../utils/StepContext";
import { UserContext } from "../../utils/UserContext";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  createTheme,
  IconButton,
  Toolbar,
  MobileStepper,
  Paper,
  ThemeProvider,
  AppBar,
  useTheme,
} from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";

function PageArrows({ prevStep, nextStep }) {
  const theme = useTheme();

  const {
    searched,
    isLoading,
    selectedProjectId,
    selectedScoreType,
    allSongsScored,
    switchToDisabled,
    currentStep, setCurrentStep,
    isDoingAnotherScore
  } = useContext(UserContext);

  const { step } = useContext(StepContext);

  const handleNext = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };

  const maxSteps = Object.keys(STEPS).length;


  const buttonStyling = {
    minWidth: "4rem",
    maxWidth: "6rem",
    minHeight: "4rem",
    maxHeight: "6rem",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
      height: "4rem",
    },
  };

  return (
<AppBar position="fixed" color="primary" sx={{ 
  top: 'auto', 
  bottom: 0, 
  right: 0, 
  left: 0, 
  display: "flex",
  flexDirection: "row",
  bgcolor: theme.palette.blackPrimary.main,
  alignItems: 'center', 
       justifyContent: 'center', 
}}
        component="footer"
        >
          <IconButton
          size="large"
          onClick={() => {
            prevStep();
           if (step !== STEPS.HOME && step !== STEPS.SEARCH) {
            handleBack();
          }
          }}
          sx={{
            color: "#fff",
          }}
        >
          {theme.direction === "rtl" ? (
            <ArrowCircleRight sx={buttonStyling} />
          ) : (
            <ArrowCircleLeft sx={buttonStyling} />
          )}
        </IconButton>
        <IconButton
          size="large"
          onClick={() => {
            nextStep();
          if ((step !== STEPS.HOME) || (step === STEPS.FAVORITE && isDoingAnotherScore)) {
            handleNext();
          }
          }}
          disabled={switchToDisabled}
          sx={{
            color: "#fff",
          }}
        >
          {theme.direction === "rtl" ? (
            <ArrowCircleLeft sx={buttonStyling} />
          ) : (
            <ArrowCircleRight sx={buttonStyling} />
          )}
        </IconButton>
    </AppBar>

 
  );
}

export default PageArrows;
