import { Box, keyframes, styled } from "@mui/material";
import Image from "mui-image";

export const scrollAnimation = keyframes`
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-15rem * 16));
    }`;

export const SliderWrapper = styled(Box)({
  width: "100%",
  height: "240px",
});

export const SliderContainer = styled(Box)({
  height: "auto",
});

export const AutoplaySlider = styled(Box)({
  position: "absolute",
  left: 0,
  right: 0,
  display: "flex",
  width: "calc(15rem * 16)",
});

export const SlideTrack = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
  animation: `${scrollAnimation} 40s linear infinite`,
});

export const IndividualSlide = styled(Box)({
  height: "15rem",
  width: "15rem",
  display: "flex",
  alignItems: "center",
});

export const SlideImage = styled("img")({
  width: "100%",
});
