import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    useTheme,
  } from "@mui/material";
  import Image from "mui-image";
  import React, { useContext, useEffect, useState } from "react";
  import AlbumResults from "../../components/listChoices/AlbumResults/AlbumResults";
  import Header from "../../components/Header/Header";
  import PageArrows from "../../components/PageArrows/PageArrows";
  import LoadingSpinner from "../../components/ui/LoadingSpinner/LoadingSpinner";
  import { STEPS } from "../../utils/constants";
  import { StepContext } from "../../utils/StepContext";
  import { UserContext } from "../../utils/UserContext";
import SearchForm from "../../components/SearchForm/SearchForm";
  
  function Searchpage() {
    const {
      albums,
      setUserFirstName,
      setUserLastName,
      setUserEmail,
      setAlbums,
      selectedProjectId,
      setSelectedProjectId,
      searched,
      setSearched,
      searchFormRef,
      isLoading,
      setSelectedProjectInfo,
      setAlbumInput,
      setSwitchToDisabled,
      setReadyToBeActive,
    } = useContext(UserContext);
  
    const theme = useTheme();
  
    const { setStep } = useContext(StepContext);
  
    const [projectSelected, setProjectSelected] = useState();
  
    useEffect(() => {
      if (selectedProjectId === "") {
        setSwitchToDisabled(true);
        setReadyToBeActive(false);
      } else {
        setSwitchToDisabled(false);
        setReadyToBeActive(true);
      }
    }, [selectedProjectId]);
  
    // Before Search
    const prevStep = () => {
      if (window.sessionStorage !== undefined) {
        const firstNameInput = window.sessionStorage.getItem("FirstName");
        firstNameInput !== null
          ? setUserFirstName(JSON.parse(firstNameInput))
          : setUserFirstName(null);
  
        const lastNameInput = window.sessionStorage.getItem("LastName");
        lastNameInput !== null
          ? setUserLastName(JSON.parse(lastNameInput))
          : setUserFirstName(null);
  
        const emailInput = window.sessionStorage.getItem("Email");
        emailInput !== null
          ? setUserEmail(JSON.parse(emailInput))
          : setUserEmail(null);
      }
  
      searchFormRef.current.reset();
      setSearched(false);
  
      setStep(STEPS.HOME);
    };
  
    // After Search
    // const afterSearchPrevStep = () => {
    //   searchFormRef.current.reset();
    //   setSearched((prevSearch) => !prevSearch);
    //   setSelectedProjectInfo({});
    //   setSelectedProjectId("");
    //   setAlbumInput("");
    //   setAlbums([]);
    //   setStep(STEPS.SEARCH);
    // };
  
    const nextStep = () => {
      if (selectedProjectId !== "") {
        setProjectSelected(true);
        setStep(STEPS.SCORETYPE);
      } else {
        setProjectSelected(false);
      }
    };
  
    return (
      <>
        <Header
          prevStep={prevStep}
          nextStep={nextStep}
        />
        <Box component="section"
        sx={{
          paddingTop: "calc(2rem + 100px)",
          paddingBottom: "1rem",
        }}
        >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <SearchForm
            projectSelected={projectSelected}
            />
          </Grid>
        </Grid>
  </Box>

        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflowY: searched && !isLoading ? "scroll" : "none",
            textAlign: "center",
            paddingBottom: "7rem",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
  
            <Grid item sm={8}>
              <AlbumResults />
            </Grid>
          </Grid>
        </Box>
  
        <PageArrows
          prevStep={prevStep}
          nextStep={nextStep}
        />
      </>
    );
  }
  
  export default Searchpage;