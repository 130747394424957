import { styled, TextField } from "@mui/material";
import React from "react";

const ResponsiveTextField = styled(TextField)(({ theme }) => ({
  [theme.breakpoints.down(500)]: {
    width: "14rem",
  },
}));

export default ResponsiveTextField;
