import {
  Pause,
  PauseCircleFilled,
  PlayArrow,
  PlayCircleFilled,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import Image from "mui-image";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../utils/UserContext";
import ShortenedText from "../../styled/ShortenedText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceGrimace,
  faFaceGrin,
  faFaceGrinHearts,
  faFaceMeh,
  faFaceSmile,
} from "@fortawesome/free-regular-svg-icons";
import { useTruncatedString } from "../../../utils/helpers";

function SongScoringList({ ratingButtonsInfo }) {
  const {
    tracks,
    setConnectionScore,
    selectedScoreType,
    allSongsScored,
    setAllSongsScored,
    currentTrackIds,
    setCurrentTrackIds,
    currentRatings,
    setCurrentRatings,
    setSwitchToDisabled,
    audioRef,
  } = useContext(UserContext);

  const isReady = useRef(false);

  const theme = useTheme();
  const renderTruncatedString = useTruncatedString();

  const [isPlaying, setIsPlaying] = useState(false);

  const [trackToPlay, setTrackToPlay] = useState(0);

  const { preview_url } = tracks[trackToPlay];

  const [isButtonSelected, setIsButtonSelected] = useState(false);

  const [theTrackId, setTheTrackId] = useState();

  const handleButtonSelected = () => {
    setIsButtonSelected(!isButtonSelected);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(preview_url);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      isReady.current = true;
    }

    audioRef.current.onended = () => {
      setIsPlaying(false);
    };
  }, [trackToPlay]);

  // useEffect(() => {
  //   console.log(currentTrackIds);
  //   console.log(currentRatings);
  // }, [currentTrackIds, currentRatings]);

  const selectOption = (trackId, theScore, freq) => {
    if (!currentTrackIds.includes(trackId)) {
      setCurrentTrackIds((prevCurrentTrackIds) => [
        ...prevCurrentTrackIds,
        trackId,
      ]);
    }

    setCurrentRatings({
      ...currentRatings,
      [trackId]: {
        score: theScore,
        frequency: freq,
      },
    });
  };

  const playAud = async () => {
    if (audioRef.current.paused && !isPlaying) {
      return audioRef.current.play();
    }
  };

  const pauseAud = () => {
    if (!audioRef.current.paused && isPlaying) {
      return audioRef.current.pause();
    }
  };

  const songElements = tracks.map((track, index) => (
    <Box key={track.id}>
      <Divider
        sx={{
          bgcolor: theme.palette.blackSecondary.main,
        }}
      />
      <Box
        component="article"
        sx={{
          paddingTop: "0.5rem",
          paddingBottom: "1rem",
          display: "block",
          paddingRight: "1.5rem",
          paddingLeft: "1.5rem",
          [theme.breakpoints.down(700)] : {
            paddingRight: 0,
          paddingLeft: 0,
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
            textAlign: "left",
          }}
        >
          {renderTruncatedString({
            customText: track.name,
            customVariant: "h5",
            customComponent: "p",
            customSx: {
              paddingRight: "1rem",
            },
            maxCharacters: {
              sm: 13,
              smToMd: 38,
              aboveMd: 72,
            },
          })}
          {/* <ShortenedText
            component="h2"
            variant="h5"
            sx={{
              paddingRight: "1rem",
              WebkitLineClamp: 1,
            }}
          >
            {track.name}
          </ShortenedText> */}
          <Box
            // className={`${
            //   track.preview_url === null
            //     ? "disabled-play-btn-container"
            //     : "play-btn-container"
            // }`}
            sx={{
              display: "flex",
            }}
          >
            <audio ref={audioRef} />
            <IconButton
              disabled={track.preview_url === null}
              onClick={() => {
                if (track.preview_url !== null) {
                  setTrackToPlay(index);
                  setIsPlaying(!isPlaying);
                  // Prevents  "Play() request was interrupted" error in Chrome
                  playAud();
                  pauseAud();
                }
              }}
              sx={{
                borderRadius: "50%",
                width: "2.5rem",
                height: "2.5rem",
                backgroundColor: theme.palette.darkPinkPrimary.main,
                "&:disabled": {
                  bgcolor: theme.palette.lightGraySecondary.main,
                },
                "&:hover": {
                  bgcolor: theme.palette.darkPinkPrimary.dark,
                },
              }}
            >
              {trackToPlay === index && isPlaying ? (
                <Pause sx={{ color: theme.palette.whitePrimary.main }} />
              ) : (
                <PlayArrow sx={{ color: theme.palette.whitePrimary.main }} />
              )}
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          {ratingButtonsInfo.map((ratingBtn) => (
            <IconButton
              key={ratingBtn.number}
              sx={{
                borderColor: ratingBtn.color,
                borderRadius: "50%",
                borderWidth: "2px",
                borderStyle: "solid",
                width: "5.5rem",
                height: "5.5rem",
                margin: "0 1rem",
                overflow: "hidden",
                bgcolor:
                  currentTrackIds.includes(track.id) &&
                  currentRatings[track.id].frequency === ratingBtn.value
                    ? ratingBtn.color
                    : theme.palette.blackPrimary.main,
                "&:hover": {
                  bgcolor: ratingBtn.color,
                },
                [theme.breakpoints.down("sm")]: {
                  margin: "0",
                  width: "4rem",
                  height: "4rem",
                },
              }}
              onClick={() => {
                setTheTrackId(track.id);
                selectOption(track.id, ratingBtn.score, ratingBtn.value);
              }}
            >
              {/* <Image
                src={`/images/ratingBars/${ratingBtn.number}-bar-rating.png`}
                alt=""
                duration={0}
                sx={{
                  width: "5rem",
                  [theme.breakpoints.down("sm")]: {
                    width: "3.5rem",
                  },
                }}
              /> */}
              <FontAwesomeIcon
                icon={ratingBtn.icon}
                size="xl"
                style={{ color: "#fff" }}
              />
            </IconButton>
          ))}
        </Box>
      </Box>
    </Box>
  ));

  useEffect(() => {
    if (
      tracks.length === currentTrackIds.length &&
      Object.keys(currentRatings).length === currentTrackIds.length
    ) {
      setAllSongsScored(true);
      setSwitchToDisabled(false);
    } else {
      setSwitchToDisabled(true);
    }
  });

  return (
    <Box component="section">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Box>
            <List
              sx={{
                padding: 0,
                margin: "8px 0",
                backgroundColor: theme.palette.darkGraySecondary.main,
              }}
            >
              {songElements}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SongScoringList;
