import React from 'react'
import { CircularProgress, Stack, useTheme } from '@mui/material'

function LoadingSpinner() {

  const theme = useTheme()

  return (
    <Stack spacing={2} direction="row">
      <CircularProgress color="darkPinkPrimary" size={60} disableShrink />
      <CircularProgress color="greenPrimary" size={60} disableShrink />
      <CircularProgress color="darkBluePrimary" size={60} disableShrink />
    </Stack>
  )
}

export default LoadingSpinner