import { AppBar, Typography, Link } from "@mui/material";
import React, { useState } from "react";
import FairUseDialog from "../FairUseDialog/FairUseDialog";

function CopyrightStatement() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{
        top: "auto",
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        flexDirection: "row",
        bgcolor: "#000",
        alignItems: "center",
        justifyContent: "center",
        height: "4rem",
      }}
      component="footer"
    >
      <Typography
        variant="body1"
        sx={{
          paddingRight: "5px",
          fontFamily: "Space Mono, monospace",
        }}
      >
        COMP&trade;, 2023 &copy; {" "}
      </Typography>
      <Link
        component="button"
        variant="body1"
        color="whitePrimary.main"
        onClick={handleOpen}
        sx={{
          fontFamily: "Space Mono, monospace",
        }}
      >
        Fair Use Statement
      </Link>
      <FairUseDialog
      open={open}
      handleClose={handleClose}
      />
    </AppBar>
  );
}

export default CopyrightStatement;
