import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { StepContextProvider } from './utils/StepContext';
import { UserContextProvider } from './utils/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StepContextProvider>
      <UserContextProvider> 
    <App />
    </UserContextProvider>
    </StepContextProvider>
);


