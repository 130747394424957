import { useContext } from "react";
import { getCurrentStep } from "./utils/helpers";
import { STEPS } from "./utils/constants";
import { StepContext } from "./utils/StepContext";
import Header from "./components/Header/Header";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import SongScoring from "./pages/Sort/SongScoring";
import FinalScore from "./pages/Score/FinalScore";

function App() {
  const theme = createTheme({
    typography: {
      // custom variant
      directions: {
        fontSize: "1.8rem",
        fontWeight: "normal",
      },
      mobileDirections: {
        fontSize: "1.3rem",
        fontWeight: "normal",
      },
      button: {
        fontWeight: 700,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: "contained",
          disableElevation: true,
        },
        styleOverrides: {
          fontWeight: 700,
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            borderRadius: "1.2rem",
            color: "#000",
          },
          input: {
            padding: "0.45rem 1.5rem",
            width: "17rem",
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            marginRight: 0,
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          root: {
            width: "40px",
            height: "40px",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            transition: "none",
            "&:hover": {
              backgroundColor: "#090c9b",
            },
          },
        },
      },
    },
    palette: {
      darkBluePrimary: {
        main: "#090c9b",
        contrastText: "#fff" ,
      },
      darkPinkPrimary:  {
        main: "#a20054",
        dark: "#75003d"
      },
      darkPurplePrimary: {
        main: "#4d089a",
        contrastText: "#fff",
      },
      darkGrayPrimary: {
        main: "#505050",
        contrastText: "#fff" ,
      },
      lightGrayPrimary: {
        main: "#888",
        contrastText: "#000",
      },
      greenPrimary: {
        main: "#218246",
        contrastText: "#fff",
      },
      orangePrimary: {
        main: "#FF7B29",
        contrastText: "#fff",
      },
      redPrimary: {
        main: "#FF1616",
        contrastText: "#fff",
      },
      blackPrimary: {
        main: "#000",
        contrastText: "#fff",
      },
      whitePrimary: {
        main: "#fff",
        contrastText: "#000",
      },
      darkGraySecondary: {
        main: "#474646",
        contrastText: "#fff" ,
      },
      lightGraySecondary:  {
        main: "rgba(211,211,211,0.8)",
        contrastText: "#000",
      },
      blackSecondary: {
        main: "#131313",
        contrastText: "#fff",
      },
      // mode: "dark",
      background: {
        default: "#000",
        // paper: "transparent",
      },
      text: {
        primary:"#ffffff",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(211,211,211,0.8)",
      },
      action: {
        disabled: "rgba(211, 211, 211, 0.8)",
      },
    },
  });

  const { step } = useContext(StepContext);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Box
          className="App"
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            left: 0,
            top: 0,
            height: "100%",
            maxHeight: "100%",
            width: "100vw",
            overflowX: "hidden",
          }}
        >
          {getCurrentStep(step)}
        </Box>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
