import Homepage from "../pages/Home/Homepage";
import SongScoring from "../pages/Sort/SongScoring";
import FavoriteSongs from "../pages/FavoriteSongs/FavoriteSongs";
import FinalScore from "../pages/Score/FinalScore";

import { STEPS } from "./constants";
import ScoreType from "../pages/ScoreType/ScoreType";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Searchpage from "../pages/Search/Searchpage";
import { useEffect, useState } from "react";

export const getCurrentStep = (step) => {
  switch (step) {
    case STEPS.HOME:
      return <Homepage />;
    case STEPS.SEARCH:
      return <Searchpage />;
    case STEPS.SCORETYPE:
      return <ScoreType />;
    case STEPS.SORT:
      return <SongScoring />;
    case STEPS.FAVORITE:
      return <FavoriteSongs />;
    case STEPS.FINALSCORE:
      return <FinalScore />;
    default:
      return (
        <Typography variant="h1">
          Invalid Step. Please refresh the page
        </Typography>
      );
  }
};

// export const useTruncatedString = (props) => {
//   const {
//     customText,
//     customVariant,
//     customComponent,
//     customSx,
//     maxCharacters,
//     varyingSizes,
//   } = props;

//   const theme = useTheme();

//   const widthAboveMd = useMediaQuery(theme.breakpoints.up("md"));

//   const widthSmToMd = useMediaQuery(theme.breakpoints.between("sm", "md"));

//   const widthBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

//   let appropriateNumOfChars;

//   switch (true) {
//     case widthAboveMd:
//       appropriateNumOfChars = maxCharacters.aboveMd;
//       break;

//     case widthSmToMd:
//       appropriateNumOfChars = maxCharacters.smToMd;
//       break;

//     case widthBelowSm:
//       appropriateNumOfChars = maxCharacters.sm;
//       break;

//       default :
//       appropriateNumOfChars = 15;
//   }

//   let truncatedString =
//     customText.length <= appropriateNumOfChars
//       ? customText
//       : `${customText.substring(0, appropriateNumOfChars)}...`;

//   return (
//     <Typography
//       variant={customVariant}
//       component={customComponent}
//       sx={customSx}
//     >
//       {truncatedString}
//     </Typography>
//   );
// };


export const handleTruncatedString = () => {
  return "hi" 
}

export const useTruncatedString = () => {

  const theme = useTheme();

  const widthAboveMd = useMediaQuery(theme.breakpoints.up("md"));

  const widthSmToMd = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const widthBelowSm = useMediaQuery(theme.breakpoints.down("sm"));

  
      const renderTruncatedString = ({
        customText,
        customVariant,
        customComponent,
        customSx,
        maxCharacters,
      }) => {
        let appropriateNumOfChars;

  switch (true) {
    case widthAboveMd:
      appropriateNumOfChars = maxCharacters.aboveMd;
      break;

    case widthSmToMd:
      appropriateNumOfChars = maxCharacters.smToMd;
      break;

    case widthBelowSm:
      appropriateNumOfChars = maxCharacters.sm;
      break;

      default :
      appropriateNumOfChars = 15;
  }

  let truncatedString =
    customText.length <= appropriateNumOfChars
      ? customText
      : `${customText.substring(0, appropriateNumOfChars)}...`;

        return (
    <Typography
      variant={customVariant}
      component={customComponent}
      sx={customSx}
    >
      {truncatedString}
    </Typography>
  )
      }
  
      return renderTruncatedString;
};
