import { Button, styled } from '@mui/material'
import React from 'react'

const ActionButton = styled(Button)(({ theme }) => ({
    border: "none",
    borderRadius: "0.7rem",
    padding: "0.75rem",
    // marginBottom: "1rem",
    // color: theme.palette.whitePrimary,
}))

export default ActionButton