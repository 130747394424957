import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  useContext,
  useEffect,
  lazy,
  Suspense,
  useState,
  useRef,
} from "react";
import { exportComponentAsPNG } from "react-component-export-image";
import Header from "../../components/Header/Header";
import ActionButton from "../../components/styled/ActionButton";
// import ShareableImage from "../../components/ui/ShareableImage/ShareableImage";
import { STEPS } from "../../utils/constants";
import { StepContext } from "../../utils/StepContext";
import { UserContext } from "../../utils/UserContext";
import CopyrightStatement from "../../components/CopyrightStatement/CopyrightStatement";
import LoadingSpinner from "../../components/ui/LoadingSpinner/LoadingSpinner";
import GeneratedImage from "../../components/GeneratedImage/GeneratedImage";
import DownloadableImage from "../../components/DownloadableImage/DownloadableImage";
import WhatsNextDialog from "../../components/WhatsNextDialog/WhatsNextDialog";

const ShareableImage = lazy(() =>
  import("../../components/ui/ShareableImage/ShareableImage")
);

function FinalScore() {
  const [openWhatsNext, setOpenWhatsNext] = useState(false);
  const handleOpenWhatsNext = () => setOpenWhatsNext(true);
  const handleCloseWhatsNext = () => setOpenWhatsNext(false);

  const [isShareableImageLoaded, setIsShareableImageLoaded] = useState(false);
  const [isImgDownloadable, setIsImgDownloadable] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const isMounted = useRef(false);
  const isDownloadable = useRef(false);

  // useEffect(() => {
  //    isMounted.current = true

  //   return () => {
  //     isMounted.current = false
  //   }

  // }, []);

  const handleShareableImageLoaded = () => {
    isMounted.current = true;
    if (isMounted) {
      setIsShareableImageLoaded(true);
    }
  };

  const handleImageDownloadable = () => {
    isDownloadable.current = true;
    if (isDownloadable) {
      setIsImgDownloadable(true);
    }
  };

  const {
    selectedScoreType,
    shareableImgRef,
    setSearched,
    setAlbums,
    setTracks,
    setSelectedProjectInfo,
    setAlbumInput,
    setSelectedProjectId,
    setIsLoading,
    setSelectedScoreType,
    setFavSongs,
    setCurrentStep,
    setConnectionScore,
    setCurrentRatings,
    setCurrentTrackIds,
    setIsDoingAnotherScore,
  } = useContext(UserContext);
  const { setStep } = useContext(StepContext);

  const theme = useTheme();

  const handleRestartData = () => {
    setSearched(false);
    setAlbums([]);
    setTracks([]);
    setSelectedProjectInfo({});
    setAlbumInput("");

    setSelectedProjectId("");

    setIsLoading(false);
    setSelectedScoreType("");
    setCurrentRatings({});
    setCurrentTrackIds([]);
    setFavSongs([]);
    setConnectionScore(0);

    setCurrentStep(0);
    isMounted.current = false;
    setIsShareableImageLoaded(false);
    setIsDoingAnotherScore(true);
    setStep(STEPS.SEARCH);
  };

  const handleExportImg = async () => {
    try {
      exportComponentAsPNG(shareableImgRef, {
        fileName: "Comp-score",
      });
    } catch (err) {
      console.error("errr: ", err);
    }
  };

  return (
    <>
      <Header />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          paddingTop: "calc(2rem + 100px)",
        }}
      >
        <Grid item>
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              textAlign: "center",
              paddingBottom: "5rem",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item sm={8}>
                <Suspense fallback={<LoadingSpinner />}>
                  <ShareableImage
                    handleShareableImageLoaded={handleShareableImageLoaded}
                    displayOnly={true}
                  />
                </Suspense>
              </Grid>

              <Grid item sm={8}>
                <Typography
                  component="p"
                  variant="directions"
                  sx={{
                    textTransform: "uppercase",
                  }}
                >
                  Share your{" "}
                  <Box
                    component="span"
                    sx={{
                      textDecoration: "underline 1px",
                      fontSize: "inherit",
                      margin: "1rem 0",
                    }}
                  >
                    {selectedScoreType.toLowerCase()}
                  </Box>{" "}
                  comp score
                </Typography>
              </Grid>

              <Grid item>
                <ButtonGroup
                  size="large"
                  variant="contained"
                  orientation="vertical"
                >
                  <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    direction="column"
                  >
                    <Grid item>
                      <ActionButton
                        disabled={!isShareableImageLoaded}
                        onClick={() => {
                          handleExportImg();
                          handleOpenWhatsNext();
                        }}
                        sx={{
                          bgcolor: theme.palette.darkPurplePrimary.main,
                        }}
                      >
                        Download
                      </ActionButton>
                    </Grid>

                    <Grid item>
                      <ActionButton
                        onClick={handleRestartData}
                        sx={{
                          bgcolor: theme.palette.darkBluePrimary.main,
                        }}
                      >
                        Do another comp score
                      </ActionButton>
                    </Grid>

                    <Grid item>
                      <ActionButton
                        href="https://compinitiative.com/manifesto"
                        sx={{
                          bgcolor: theme.palette.darkPinkPrimary.main,
                        }}
                      >
                        Learn more
                      </ActionButton>
                    </Grid>
                  </Grid>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          opacity: 0,
          position: "absolute",
          top: "-100%",
          left: "50%",
          transform: "translateX(50%)",
          // bottom styling allows img to be viewable at bottom of page but comment out previous styling
          // marginBottom: "7rem",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center"
        }}
      >
        <DownloadableImage
          handleImageDownloadable={handleImageDownloadable}
          displayOnly={false}
        />
      </Box>
      <CopyrightStatement />
      <WhatsNextDialog
        open={openWhatsNext}
        handleClose={handleCloseWhatsNext}
      />
    </>
  );
}

export default FinalScore;
