import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../utils/UserContext";
import LoadingSpinner from "../../ui/LoadingSpinner/LoadingSpinner";
import ProjectListButton from "../../styled/ProjectListButton";
import ShortenedText from "../../styled/ShortenedText";
import { useTruncatedString } from "../../../utils/helpers";

function AlbumResults() {
  const {
    albums,
    selectedProjectId,
    setSelectedProjectId,
    searched,
    isLoading,
    setSelectedProjectInfo,
  } = useContext(UserContext);

  const theme = useTheme();
  const renderTruncatedString = useTruncatedString()
  const generatedAlbums = albums.map((album) => (
    <ListItem key={album.id}>
      <ProjectListButton
        sx={{
          bgcolor:
            selectedProjectId === album.id
              ? theme.palette.darkBluePrimary.main
              : theme.palette.blackPrimary.main,
          minWidth: isLoading && 300,
        }}
        onClick={() => {
          setSelectedProjectId(album.id);
          setSelectedProjectInfo((prevInfo) => ({
            ...prevInfo,
            ...album,
          }));
        }}
      >
        {isLoading ? (
          <Skeleton variant="rectangular" width="6.5rem" height="6.5rem" />
        ) : (
          <img
            src={album.images[0].url}
            alt={album.name}
            style={{
              width: "6.5rem",
              height: "6.5rem",
            }}
          />
        )}
        <ListItemText
          sx={{
            margin: "0.5rem",
          }}
          primary={
            isLoading ? (
              <Typography variant="h6">
                <Skeleton />
              </Typography>
            ) : (
              renderTruncatedString({
                customText: album.name,
                customVariant: "h6",
                customComponent: "span",
                customSx: {
                  fontWeight: 600,
                  display: "block",
                  color: theme.palette.whitePrimary.main,
                },
                maxCharacters: {
                  sm: 13,
                  smToMd: 37,
                  aboveMd: 63,
                },
              })
            )
          }
          secondary={
            isLoading ? (
              <Typography variant="body1">
                <Skeleton />
              </Typography>
            ) : (
              renderTruncatedString({
                customText: album.artists[0].name,
                customVariant: "body1",
                customComponent: "span",
                customSx: {
                  display: "inline",
                  color: theme.palette.whitePrimary.main,
                },
                maxCharacters: {
                  sm: 13,
                  smToMd: 37,
                  aboveMd: 63,
                },
              })
            )
          }
        ></ListItemText>
      </ProjectListButton>
    </ListItem>
  ));

  return (
    <Box component="section">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {searched && isLoading ? (
          <Grid item>
            <LoadingSpinner />
          </Grid>
        ) : (
          <Grid item>
            <Box>
              {albums.length === 0 && searched ? (
                <Typography variant="body1">No Results</Typography>
              ) : (
                <List
                  sx={{
                    padding: 0,
                    margin: "8px 0",
                    backgroundColor: theme.palette.darkGraySecondary.main,
                  }}
                >
                  {generatedAlbums}
                </List>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default AlbumResults;
