import { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller, useController } from "react-hook-form";
import { UserContext } from "../../utils/UserContext";
import { StepContext } from "../../utils/StepContext";
import { STEPS } from "../../utils/constants";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
  LoadingButton,
  useTheme,
} from "@mui/material";
import ResponsiveTextField from "../styled/ResponsiveTextField";

function UserForm({ open, handleCloseForm }) {
  const {
    userFirstName,
    userLastName,
    userEmail,
    setUserFirstName,
    setUserLastName,
    setCurrentStep,
    setUserEmail,
  } = useContext(UserContext);
  const { setStep } = useContext(StepContext);

  const theme = useTheme();

  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const firstNameRef = useRef(userFirstName);
  const lastNameRef = useRef(userLastName);
  const emailRef = useRef(userEmail);

  function updateUserInfoState(
    newFirstNameState,
    newLastNameState,
    newEmailState
  ) {
    firstNameRef.current = newFirstNameState;
    lastNameRef.current = newLastNameState;
    emailRef.current = newEmailState;

    setUserFirstName(newFirstNameState);
    setUserLastName(newLastNameState);
    setUserEmail(newEmailState);
  }

  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true)
    await setUserFirstName(data.firstName);
    await setUserLastName(data.lastName);
    await setUserEmail(data.email);

    const formData = new FormData();
    formData.append('form-name', 'contact'); // The name of your Netlify form
    Object.keys(data).forEach(key => formData.append(key, data[key]));
  
    setIsSubmitting(true);
  
    try {
      await fetch("/", {
        method: "POST",
        body: formData
      });
      console.log('Submission successful');
      handleCloseForm();
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setIsSubmitting(false);
    };

    setStep(STEPS.FINALSCORE);
    setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
  };

  useEffect(() => {
    if (
      userFirstName.length >= 1 &&
      userLastName.length >= 1 &&
      userEmail.length > 1
    ) {
      window.sessionStorage.setItem("FirstName", JSON.stringify(userFirstName));
      window.sessionStorage.setItem("LastName", JSON.stringify(userLastName));
      window.sessionStorage.setItem("Email", JSON.stringify(userEmail));

      updateUserInfoState(userFirstName, userLastName, userEmail);

      setValue("firstName", userFirstName);
      setValue("lastName", userLastName);
      setValue("email", userEmail);
      setIsSubmitting(false)
    }
  }, [userFirstName, userLastName, userEmail]);

  return (
    <Dialog open={open} onClose={handleCloseForm}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: theme.palette.blackSecondary.main,
        }}
      >

        <DialogTitle>
          You're so close to getting your COMP Score! But first, fill out the
          info below so we can personalize it for you!
        </DialogTitle>
        
        <DialogContent>
        <Box
        component="form"
        method="post"
        name="contact"
        action="/contact"
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: theme.palette.blackSecondary.main,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
             {/* Hidden input for Netlify form handling */}
    <input type="hidden" name="form-name" value="contact" />

        <Grid container alignItems="center" direction="column" spacing={2}>
          <Grid item xs={6} md={8}>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: "First Name is Required",
              }}
              // get the field state
              render={({ field, fieldState: { error } }) => (
                <ResponsiveTextField
                  variant="outlined"
                  {...field}
                  required
                  type="text"
                  placeholder="First Name"
                  //  is error ??
                  error={errors.firstName ? true : false}
                  // show helper text if it is invalid
                />
              )}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.firstName?.message}
            </Typography>
          </Grid>

          <Grid item xs={6} md={8}>
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: "Last Name is Required",
              }}
              // get the field state
              render={({ field, fieldState: { error } }) => (
                <ResponsiveTextField
                  variant="outlined"
                  {...field}
                  required
                  type="text"
                  placeholder="Last Name"
                  //  is error ??
                  error={errors.lastName ? true : false}
                  // show helper text if it is invalid
                />
              )}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.lastName?.message}
            </Typography>
          </Grid>

          <Grid item xs={6} md={8}>
            <Controller
              control={control}
              name="email"
              rules={{
                required: {
                  value: true,
                  message: "Email is Required",
                },
                pattern: {
                  value: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
                  message: "Email is Invalid",
                },
              }}
              // get the field state
              render={({ field, fieldState: { error, invalid } }) => (
                <ResponsiveTextField
                  variant="outlined"
                  {...field}
                  required
                  type="email"
                  placeholder="Email"
                  //  is error ??
                  error={errors.email ? true : false}
                  // show helper text if it is invalid
                />
              )}
            />
            <Typography variant="inherit" color="textSecondary">
              {errors.email?.message}
            </Typography>
          </Grid>

          <Grid item>
            <Button
            disabled={isSubmitting}
              sx={{
                bgcolor: theme.palette.darkBluePrimary.main,
              }}
              type="submit"
            >
              See My Score
            </Button>
          </Grid>
        </Grid>
        </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default UserForm;
