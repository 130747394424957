import React, { useContext, useState, useEffect, useRef, forwardRef } from "react";
import { Box, createTheme, Grid, Typography, useTheme, ThemeProvider } from "@mui/material";
import { UserContext } from '../../utils/UserContext';
import ImgDoubleCols from "../ImgDoubleCols/ImgDoubleCols";
import TimeOfImage from "../TimeOfImage/TimeOfImage";


// const Downloadable = forwardRef((props, ref) => {
//     const { displayOnly } = props
//     const { userFirstName, connectionScore } = useContext(UserContext);
//     const theme = useTheme();

//     const [connectionLevel, setConnectionLevel] = useState("");
//     const DownloadableRef = useRef();
//     useEffect(() => {
//       // Make sure to assign the ref to the internal HTML element
//       if (ref) {
//         ref.current = DownloadableRef.current;
//       }
//     }, [ref]);

//     const shareableImageTheme = createTheme({
//       typography: {
//         fontFamily: [
//           '"Space Mono"', 
//           'monospace',
//         ].join(','),
//       },
//       components: {
//         MuiListItemIcon: {
//           styleOverrides: {
//             root: {
//               minWidth: "20px",
//             }
//           }
//         },
//         MuiListItem: {
//           styleOverrides: {
//             root: {
//               paddingTop: 0,
//               paddingBottom: 0,
//             }
//           }
//         },
//         MuiListItemText: {
//           styleOverrides: {
//             root: {
//               margin: 0,
//             }
//           }
//         },
//       },
//       palette: {
//         goldPrimary: "#F4C01E",
//         whitePrimary: theme.palette.whitePrimary.main,
//         blackPrimary: theme.palette.blackPrimary.main,
//       }
//     })

//     const handleConnectionLevel = () => {
//         switch(true){
//           case (connectionScore >= 9.0 && connectionScore <= 10):
//             setConnectionLevel("classic");
//             break;
//             case (connectionScore >= 8.0 && connectionScore <= 8.9):
//               setConnectionLevel("ultra");
//               break;
//               case (connectionScore >= 7.0 && connectionScore <= 7.9):
//                 setConnectionLevel("high");
//                 break;
//                 case (connectionScore >= 6.0 && connectionScore <= 6.9):
//             setConnectionLevel("medium");
//             break;
//             case (connectionScore >= 5.0 && connectionScore <= 5.9):
//             setConnectionLevel("low");
//             break;
//             case (connectionScore < 4.9):
//             setConnectionLevel("notforme");
//             break;
//             default:
//           return console.error("Invalid Value!")
//         }
//       };

//       const returnStampColor = () => {
//         switch (connectionLevel) {
//           case "classic":
//             // blackSecondary.main
//             return "#000";
    
//           case "ultra":
//             // darkPurplePrimary.main
//             return "#4d089a";
    
//           case "high":
//             // darkBluePrimary.main
//             return "#090c9b";
    
//           case "medium":
//             // greenPrimary.main
//             return "#218246";
    
//           case "low":
//             // orangePrimary.main
//             return "#FF7B29";
    
//           case "notforme":
//             // redPrimary.main
//             return "#FF1616";
    
//           default:
//             return null;
//         }
//       };

//       useEffect(() => {
//         handleConnectionLevel();
//       });

//     return (
//       <div>
//        <ThemeProvider theme={shareableImageTheme}>
// <Box
//     component="article"
//     ref={DownloadableRef}
//     sx={{
//       bgcolor: theme.palette.blackSecondary,
//       width: displayOnly ? "475px" : "1080px",
//       height: displayOnly ? "475px" : "1080px",
//       padding: "0 1.5rem",
//       paddingTop: "2rem",
//     }}
//   >
//     <Grid
// container
// item
// spacing={6}
// direction="column"
// justifyContent="center"
// alignItems="center"
// >

// <Grid item>
//     <Typography
//       component="p"
//       variant="h5"
//       sx={{
//         fontWeight: 600,
//         // fontSize: "1.8rem",
//         textTransform: "uppercase",
//         textAlign: "center",
//       }}
//     >
//       {userFirstName}
//       {`${userFirstName.slice(-1) === "s" ? "'" : "'s"}`} Comp Score
//     </Typography>
//     </Grid>
//     <ImgDoubleCols
//     connectionLevel={connectionLevel} 
//     returnStampColor={returnStampColor}
//     />
//     <TimeOfImage />
//     </Grid>
//   </Box>

//        </ThemeProvider>
//       </div>
    

//   )}
//   );

function DownloadableImage({handleImageDownloadable, displayOnly}) {
  const { userFirstName, shareableImgRef, connectionScore } = useContext(UserContext);

      const theme = useTheme();

    const [connectionLevel, setConnectionLevel] = useState("");
   
    useEffect(() => {
      handleImageDownloadable()
    }, [])

    const shareableImageTheme = createTheme({
      typography: {
        fontFamily: [
          '"Space Mono"', 
          'monospace',
        ].join(','),
      },
      components: {
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: "20px",
            }
          }
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              margin: 0,
            }
          }
        },
      },
      palette: {
        goldPrimary: "#F4C01E",
        whitePrimary: theme.palette.whitePrimary.main,
        blackPrimary: theme.palette.blackPrimary.main,
      }
    })

    const handleConnectionLevel = () => {
        switch(true){
          case (connectionScore >= 9.0 && connectionScore <= 10):
            setConnectionLevel("classic");
            break;
            case (connectionScore >= 8.0 && connectionScore <= 8.9):
              setConnectionLevel("ultra");
              break;
              case (connectionScore >= 7.0 && connectionScore <= 7.9):
                setConnectionLevel("high");
                break;
                case (connectionScore >= 6.0 && connectionScore <= 6.9):
            setConnectionLevel("medium");
            break;
            case (connectionScore >= 5.0 && connectionScore <= 5.9):
            setConnectionLevel("low");
            break;
            case (connectionScore < 4.9):
            setConnectionLevel("notforme");
            break;
            default:
          return console.error("Invalid Value!")
        }
      };

      const returnStampColor = () => {
        switch (connectionLevel) {
          case "classic":
            // blackSecondary.main
            return "#000";
    
          case "ultra":
            // darkPurplePrimary.main
            return "#4d089a";
    
          case "high":
            // darkBluePrimary.main
            return "#090c9b";
    
          case "medium":
            // greenPrimary.main
            return "#218246";
    
          case "low":
            // orangePrimary.main
            return "#FF7B29";
    
          case "notforme":
            // redPrimary.main
            return "#FF1616";
    
          default:
            return null;
        }
      };

      useEffect(() => {
        handleConnectionLevel();
      });

    return (
      <Box sx={{
        opacity: 1,
      }}>
       <ThemeProvider theme={shareableImageTheme}>
        
    <Box
              component="article"
              ref={shareableImgRef}
              sx={{
                bgcolor: theme.palette.blackSecondary.main,
                width: "900px",
                height: "900px",
                padding: "0 4rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
              }}
            >
              <Grid
          container
          spacing={12}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          
          <Grid item xs={4}>
              <Typography
                component="p"
                variant="h3"
                sx={{
                  fontWeight: 600,
                  // fontSize: "1.8rem",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {userFirstName}
                {`${userFirstName.slice(-1) === "s" ? "'" : "'s"}`} Comp Score
              </Typography>
              </Grid>
              <ImgDoubleCols
              connectionLevel={connectionLevel} 
              returnStampColor={returnStampColor}
              displayOnly={displayOnly}
              />
              <TimeOfImage
              displayOnly={displayOnly}
              />
              </Grid>
            </Box>
            </ThemeProvider>
            </Box>
  )
}

export default DownloadableImage