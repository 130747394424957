import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../../utils/UserContext";

function ScoreTypesList() {
  const theme = useTheme();

  const scoreBtnDetails = [
    {
      id: 1,
      name: "Nostalgia",
      summary: "as your past self",
    },
    {
      id: 2,
      name: "React",
      summary: "your first listen",
    },
    {
      id: 3,
      name: "Replay",
      summary: "your recent relisten",
    },
  ];

  const { selectedScoreType, setSelectedScoreType } = useContext(UserContext);

  const scoreBtns = scoreBtnDetails.map((btn) => (
    <ListItem
      key={btn.id}
      sx={{
        paddingTop: "4px",
        paddingBottom: "4px",
      }}
    >
      <ListItemButton
        sx={{
          bgcolor:
            selectedScoreType === btn.name
              ? theme.palette.darkBluePrimary.main
              : theme.palette.blackPrimary.main,
          textAlign: "center",
          border: `3px solid  ${theme.palette.blackSecondary.main}`,
          borderRadius: "2rem",
          padding: "0.25rem 2rem",
        }}
        onClick={() => {
          setSelectedScoreType(btn.name);
        }}
      >
        <ListItemText
          sx={{
            margin: "0.5rem",
          }}
          primary={
            <Typography
              sx={{
                fontWeight: 600,
                fontStyle: "italic",
                display: "block",
                textTransform: "uppercase",
              }}
              component="span"
              variant="h5"
              color={theme.palette.whitePrimary.main}
            >
              {btn.name} Score
            </Typography>
          }
          secondary={
            <Typography
              sx={{
                display: "inline",
              }}
              component="span"
              variant="body1"
              color={theme.palette.whitePrimary.main}
            >
              Score {btn.summary}
            </Typography>
          }
        ></ListItemText>
      </ListItemButton>
    </ListItem>
  ));

  return (
    <Box component="section">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Box>
            <List
              sx={{
                padding: 0,
                margin: "8px 0",
                backgroundColor: theme.palette.darkGraySecondary.main,
              }}
            >
              {scoreBtns}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ScoreTypesList;
