import React from "react";
import {
  AutoplaySlider,
  IndividualSlide,
  SlideImage,
  SlideTrack,
  SliderContainer,
  SliderWrapper,
} from "../../styled/SliderElements";

function InfiniteSlider() {
  const slides = [
    {
      id: 1,
      name: "Lana Del Rey's Born to Die Album Cover",
      url: "/images/albumCovers/lana-del-rey-born-to-die.jpg",
    },
    {
      id: 2,
      name: "Kanye West's Graduation Album Cover",
      url: "/images/albumCovers/kanye-west-graduation.jpg",
    },
    {
      id: 3,
      name: "Kendrick Lamar's DAMN Album Cover",
      url: "/images/albumCovers/kendrick-lamar-damn.jpg",
    },
    {
      id: 4,
      name: "Drake's Take Care Album Cover",
      url: "/images/albumCovers/drake-take-care.jpg",
    },
    {
      id: 5,
      name: "Janelle Monae's Dirty Computer Album Cover",
      url: "/images/albumCovers/janelle-monae-dirty-computer.jpg",
    },
    {
      id: 6,
      name: "Beyoncé's B'Day Album Cover",
      url: "/images/albumCovers/beyonce-bday.jpg",
    },
    {
      id: 7,
      name: "The Weeknd's Beauty Behind the Madness Album Cover",
      url: "/images/albumCovers/the-weeknd-beauty-behind-the-madness.jpg",
    },
    {
      id: 8,
      name: "Shakira's Shakira Album Cover",
      url: "/images/albumCovers/shakira-shakira.jpg",
    },
    {
      id: 9,
      name: "Frank Ocean's Blond Album Cover",
      url: "/images/albumCovers/frank-ocean-blond.jpeg",
    },
    {
      id: 10,
      name: "Michael Jackson's Thriller Album Cover",
      url: "/images/albumCovers/michael-jackson-thriller.jpg",
    },
    {
      id: 11,
      name: "The Beatles' Abbey Road Album Cover",
      url: "/images/albumCovers/the-beatles-abbey-road.jpg",
    },
    {
      id: 12,
      name: "Dionne Warwick's make way for dionne warwick album cover",
      url: "/images/albumCovers/dionne-warwick-make-way-for-dionne-warwick.jpg",
    },
    {
      id: 13,
      name: "Gorillaz's Demon Days Album Cover",
      url: "/images/albumCovers/gorillaz-demon-days.jpg",
    },
    {
      id: 14,
      name: "Taylor Swift's 1989 Album Cover",
      url: "/images/albumCovers/taylor-swift-1989.jpg",
    },
    {
      id: 15,
      name: "Adele's 21 Album Cover",
      url: "/images/albumCovers/adele-21.jpg",
    },
    {
      id: 16,
      name: "Billie Eilish's Happier Than Ever Album Cover",
      url: "/images/albumCovers/billie-eilish-happier-than-ever.jpg",
    },
  ];

  const infiniteSlides = slides.map((slide) => (
    <IndividualSlide key={slide.id}>
      <SlideImage src={slide.url} alt={slide.name} />
    </IndividualSlide>
  ));

  return (
    <SliderWrapper>
      <SliderContainer>
        <AutoplaySlider>
          <SlideTrack>{infiniteSlides}</SlideTrack>
          <SlideTrack>{infiniteSlides}</SlideTrack>
        </AutoplaySlider>
      </SliderContainer>
    </SliderWrapper>
  );
}

export default InfiniteSlider;
