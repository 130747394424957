import { Star } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "../../../utils/UserContext";
import ShortenedText from "../../styled/ShortenedText";
import { useTruncatedString } from "../../../utils/helpers";

function FavoriteSongsList() {
  const { tracks, favSongs, setFavSongs, selectedScoreType } =
    useContext(UserContext);

  const theme = useTheme();
  const renderTruncatedString = useTruncatedString()
  const handleAdd = (songName) => {
    if (favSongs.length === 3) {
      setFavSongs((prevFavSongs) => [...prevFavSongs.slice(1), songName]);
    } else {
      setFavSongs((prevFavSongs) => [...prevFavSongs, songName]);
    }
  };

  const handleRemove = (songName) => {
    setFavSongs((prevFavSongs) =>
      prevFavSongs.filter((val) => val !== songName)
    );
  };

  const favoriteSongElements = tracks.map((track) => (
    <Box key={track.id}>
      <Divider
        sx={{
          bgcolor: theme.palette.blackSecondary.main,
        }}
      />
      <Box
        component="article"
        sx={{
          padding: " 0.5rem 0",
          display: "block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem 1rem",
            justifyContent: "space-between",
          }}
        >
          {renderTruncatedString({
            customText: track.name,
            customVariant: "h5",
            customComponent: "p",
            customSx: {
              paddingRight: "1rem",
            },
            maxCharacters: {
              sm: 13,
              smToMd: 38,
              aboveMd: 66,
            },
          })}
          {/* <ShortenedText
            component="h2"
            variant="h5"
            sx={{
              paddingRight: "1rem",
              WebkitLineClamp: 1,
            }}
          >
            {track.name}
          </ShortenedText> */}
          <IconButton
            onClick={() => {
              if (!favSongs.includes(track.name)) {
                handleAdd(track.name);
              } else {
                handleRemove(track.name);
              }
            }}
            sx={{
              borderColor: theme.palette.darkPinkPrimary.main,
              borderRadius: "50%",
              borderWidth: "2px",
              borderStyle: "solid",
              width: "2.5rem",
              height: "2.5rem",
              backgroundColor: favSongs.includes(track.name)
                ? theme.palette.darkPinkPrimary.main
                : theme.palette.blackPrimary.main,
              "&:hover": {
                bgcolor: theme.palette.darkPinkPrimary.dark,
              },
            }}
          >
            <Star sx={{ color: theme.palette.whitePrimary.main }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  ));

  return (
    <Box component="section">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Box>
            <List
              sx={{
                padding: 0,
                margin: "8px 0",
                backgroundColor: theme.palette.darkGraySecondary.main,
              }}
            >
              {favoriteSongElements}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FavoriteSongsList;
